/**
 * Context used for the Katashiki Setup Page.
 * Use this to manage any common data that has to be shared within the Katashiki Setup components.
 * Reducer is used to dispatch the actions to update the context values.
 */
import PropTypes from 'prop-types';
import React, { createContext, useReducer } from 'react';
import KatashikiDetails from '../json/KatashikiVehicleDetails.config';

const initialState = {
  currentView: 'Summary View',
  selectedRow: {},
  selectedTab: 0,
  specSelectedTab: 0,
  isPanelOpen: false,
  selectedKatashikiDetails: null,
  notificationselectedData: [],
  SelectedNotification: null,
  navigationfrom: null,
};

export const KatashikiContext = createContext();

export const katashikiReducer = (state, action) => {
  switch (action.type) {
    case 'CURRENT_VIEW':
      return {
        ...state,
        currentView: action.payload,
      };
    case 'TABLE_ONSELECTION': {
      const data =
        KatashikiDetails.filter((x) => x.id === action.payload.id).length > 0
          ? KatashikiDetails.filter((x) => x.id === action.payload.id)[0]
          : null;
      return {
        ...state,
        selectedKatashikiDetails: data,
      };
    }
    case 'TAB_SELECTED':
      return {
        ...state,
        selectedTab: action.payload,
      };
    case 'IS_PANEL_OPEN':
      return {
        ...state,
        isPanelOpen: action.payload,
      };
    case 'SET_NAVIGATION_DATA': {
      let notificationselectedData = [...state.notificationselectedData];
      if (action.payload?.id) {
        const notifcation = state.notificationselectedData.filter(
          (x) => x.id === action.payload.id,
        );
        notificationselectedData =
          notifcation.length === 0
            ? [...state.notificationselectedData, { ...action.payload }]
            : [...state.notificationselectedData];
      }
      return {
        ...state,
        SelectedNotification: action.payload,
        notificationselectedData: notificationselectedData,
      };
    }
    case 'SET_NAVIGATION':
      return {
        ...state,
        navigationfrom: action.payload,
      };
    default:
      return state;
  }
};

const KatashikiProvider = ({ children }) => {
  const [state, dispatch] = useReducer(katashikiReducer, initialState);

  const updateCurrentView = (value) => {
    dispatch({
      type: 'CURRENT_VIEW',
      payload: value,
    });
  };

  const setSelectedrow = (value) => {
    dispatch({
      type: 'TABLE_ONSELECTION',
      payload: value,
    });
  };

  const setSelectedTab = (value) => {
    dispatch({
      type: 'TAB_SELECTED',
      payload: value,
    });
  };

  const setIsPanelOpen = (value) => {
    dispatch({
      type: 'IS_PANEL_OPEN',
      payload: value,
    });
  };

  const setNavigationData = (value) => {
    dispatch({
      type: 'SET_NAVIGATION_DATA',
      payload: value,
    });
  };

  const setNavigate = (value) => {
    dispatch({
      type: 'SET_NAVIGATION',
      payload: value,
    });
  };

  return (
    <KatashikiContext.Provider
      value={{
        state,
        updateCurrentView,
        setSelectedrow,
        setSelectedTab,
        setIsPanelOpen,
        setNavigationData,
        setNavigate,
      }}
    >
      {children}
    </KatashikiContext.Provider>
  );
};

KatashikiProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default KatashikiProvider;
